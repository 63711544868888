import { Tag, Video } from '@tivio/types'
import { useMemo } from 'react'

import { useItemsInRow } from './useItemsInRow'


const useItemDetail = <T extends Video | Tag>(itemId: string, rowId: string) => {
    const { pagination } = useItemsInRow(rowId)

    return  useMemo(
        () => {
            if (!pagination) {
                return null
            }

            const itemIndex = pagination.items.findIndex(item => item.id === itemId)

            const currentItem = pagination.items[itemIndex]
            const nextItem = pagination.items[itemIndex + 1]
            const nextItems = pagination.items.splice(itemIndex)

            return {
                currentItem: currentItem as T,
                nextItem: nextItem as T,
                nextItems: nextItems as T[],
            }
        },
        [pagination],
    )
}

export {
    useItemDetail,
}
