import { tivio } from '@tivio/core-js'
import { useEffect, useState } from 'react'

import type { Row, UseRow } from '@tivio/types'


export const useRow: UseRow = (rowId) => {
    const [row, setRow] = useState<Row | null>(null)
    const [error, setError] = useState<Error | null>(null)
    useEffect(() => {
        if (rowId) {
            tivio.getRowById(rowId).then((row) => setRow(row as any as Row)).catch(setError)
        }
    }, [rowId])

    return {
        row,
        error,
    }
}
