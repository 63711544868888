import { Row } from '@tivio/core-js'
import { useEffect, useState } from 'react'


export const useGroupedRows = (rows: Row[]) => {
    const [groupedRows, setGroupedRows] = useState<{ sectionId: string; organizationId: string | null; rows: Row[] }[]>([])
    const [expandedSectionStates, setExpandedSectionStates] = useState<Record<string, boolean>>({})

    useEffect(() => {
        const sections: { sectionId: string; organizationId: string | null; rows: Row[] }[] = []
        const newExpandedSections: Record<string, boolean> = {}

        let sectionIndex = 0

        for (const row of rows) {
            const organizationId = row?.organizationId
            const sectionId = `section-${sectionIndex}`

            if (organizationId === undefined) {
                sections.push({ sectionId, organizationId: null, rows: [row] })
                newExpandedSections[sectionId] = false
                sectionIndex++
            } else {
                const previousSection = sections[sections.length - 1]
                if (
                    previousSection &&
                    previousSection.rows.length > 0 &&
                    previousSection.organizationId === organizationId
                ) {
                    previousSection.rows.push(row)
                } else {
                    sections.push({ sectionId, organizationId, rows: [row] })
                    newExpandedSections[sectionId] = false
                    sectionIndex++
                }
            }
        }

        setGroupedRows(sections)
        setExpandedSectionStates(prevExpandedSections => ({
            ...prevExpandedSections,
            ...newExpandedSections,
        }))
    }, [rows])

    return { groupedRows, expandedSectionStates }
}
