import { tivio, Voucher } from '@tivio/core-js'
import { useCallback, useEffect, useState } from 'react'

import { useError } from './useError'
import { useUser } from './useUser'


const useVoucher = (voucherId: string) => {
    const { user } = useUser()
    const organizationRef = tivio.organization?.ref
    const [voucher, setVoucher] = useState<Voucher | null>(null)
    const [isVoucherInitialized, setIsVoucherInitialized] = useState(false)
    const [purchaseId, setPurchaseId] = useState<string | null>(null)
    const [, setActivatedPromotionsIds] = useState<string[]>([])
    const [activationSuccess, setActivationSuccess] = useState(false)
    const [redirectToSubscriptions, setRedirectToSubscriptions] = useState(false)
    const { error, raiseError, resetError } = useError()

    useEffect(() => {
        // Initialize Voucher instance asynchronously
        const initVoucher = async () => {
            const v = new Voucher(voucherId, undefined, organizationRef)
            await v.initialize()
            setVoucher(v)
            setIsVoucherInitialized(true)
        }

        initVoucher()
    }, [voucherId, organizationRef])

    useEffect(() => {
        if (purchaseId && user?.purchases && isVoucherInitialized) {
            const exist = user.purchases.some((purchase) => purchase.id === purchaseId)

            if (exist) {
                voucher?.refresh()
                setActivationSuccess(true)
            }
        }
    }, [purchaseId, user?.purchases, isVoucherInitialized, voucher])

    const activate = useCallback(async () => {
        if (!isVoucherInitialized) {
            return
        }

        resetError()

        try {
            const res = await voucher?.activate() ?? null
            if (typeof res === 'string') {
                setPurchaseId(res)
            } else if (typeof res === 'object' && res?.activatedPromotions) {
                if (res.activatedPromotions.some(promotion => !promotion.isFree)) {
                    setRedirectToSubscriptions(true)
                }
                setActivatedPromotionsIds(res.activatedPromotions.map(promotion => promotion.id))
                if (res.activatedPromotions.length) {
                    setActivationSuccess(true)
                }
            }
        } catch (e) {
            raiseError(e)
        }
    }, [isVoucherInitialized, voucher, resetError, raiseError])

    return {
        activate,
        voucher,
        error,
        activationSuccess,
        isVoucherInitialized,
        redirectToSubscriptions,
    }
}

export { useVoucher }