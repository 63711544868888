import { tivio } from '@tivio/core-js'
import { ItemInRow, PaginationInterface, ROW_ITEM_TYPES, SubscribeToItemsInRowOptions } from '@tivio/types'
import { useEffect, useState } from 'react'

import { useError } from './useError'


/**
 * Use row items
 * @deprecated use useNewRowsInScreen instead
 * @param rowId - row ID
 * @param options - subscription options
 */
export const useItemsInRow = (
    rowId?: string,
    options: SubscribeToItemsInRowOptions = {
        noLimit: false,
        limit: 3,
        fetchTags: true,
    },
) => {
    const [pagination, setPagination] = useState<PaginationInterface<ItemInRow> | null>(null)
    const { error, raiseError } = useError()

    const orderBy = options.orderBy?.[0]

    useEffect(
        () => {
            let isMounted = true

            if (!rowId) {
                return
            }

            const disposer = tivio.subscribeToItemsInRow(
                rowId,
                (error, data) => {
                    if (!isMounted) {
                        return
                    }

                    if (error) {
                        raiseError(error)
                    }

                    if (data) {
                        if (options.fetchTags) {
                            // TODO - hack so mobx react on tags changes, remove this with custom comparer
                            data.items.forEach(video => {
                                video.name
                                if (video.itemType === ROW_ITEM_TYPES.VIDEO || video.itemType === ROW_ITEM_TYPES.TV_CHANNEL) {
                                    video.isPlayable
                                }
                                // @ts-ignore
                                video.tags?.forEach?.(tag => ({
                                    name: tag.name,
                                    type: tag.type,
                                    metadata: tag.metadata,
                                    color: tag.color,
                                }))
                            })
                        }

                        setPagination({
                            items: data.items,
                            fetchMore: data.fetchMore,
                            hasNextPage: data.hasNextPage,
                            loading: data.loading,
                        })
                    }
                },
                options,
            )

            return () => {
                isMounted = false
                disposer?.()
            }
        },
        [rowId, orderBy],
    )

    return {
        pagination,
        error,
    }
}
