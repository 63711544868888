import { Logger } from '@tivio/common'
import { Tag, Video } from '@tivio/types'
import { useCallback, useEffect, useState } from 'react'


const logger = new Logger('useIsFavorite')

/**
 * Handles favorite state for video or tag (used for series now).
 * State from video/tag entity is duplicated in React state of this hook to make UI faster
 * (changing favorite icon don't need to wait until request to firebase will end)
 */
export const useIsFavorite = (item?: Video | Tag | null) => {
    const [ isFavorite, setIsFavorite ] = useState(item?.isFavorite ?? false)
    const [ isLoading, setIsLoading ] = useState(false)

    const toggleFavorite = useCallback(() => {
        if (!item) {
            logger.warn('No item provided')
            return
        }

        setIsFavorite(prevState => !prevState)
        setIsLoading(true)

        item.isFavorite ? item.removeFromFavorites() : item.addToFavorites()
    }, [ item, setIsFavorite, setIsLoading ])

    useEffect(() => {
        setIsFavorite(item?.isFavorite ?? false)
        setIsLoading(false)
    }, [ item?.isFavorite, setIsFavorite, setIsLoading ])

    return { isFavorite, isLoading, toggleFavorite }
}
