import {
    Advertisement,
    Application,
    Article,
    AssetType,
    BannerItemComponent,
    ItemInRow,
    Row,
    ROW_ITEM_TYPES,
    RowBanner,
    RowComponent,
    RowItemComponent,
    RowStandard,
    ScreenRowType,
    Tag,
    TvChannel,
    Video,
} from '@tivio/types'

import Translator from '../services/Translator'


const ROW_ITEM_TYPE_TO_ASSET_ID: {
    [key in RowItemComponent | BannerItemComponent] : AssetType
} = {
    [RowItemComponent.ROW_ITEM_PORTRAIT]: 'portrait',
    [RowItemComponent.ROW_ITEM_LANDSCAPE]: 'landscape',
    [RowItemComponent.ROW_ITEM_SQUARE]: 'square',
    [RowItemComponent.ROW_ITEM_CREATORS_SQUARE]: 'square',
    [RowItemComponent.ROW_ITEM_CREATORS_LANDSCAPE]: 'landscape',
    [RowItemComponent.ROW_ITEM_CIRCLED]: 'circled',
    [RowItemComponent.ROW_ITEM_HIGHLIGHTED]: 'landscape',
    [RowItemComponent.ROW_ITEM_PLAIN]: 'landscape',

    // Maybe in future we may want a dedicated 'banner' AssetType value for these.
    [BannerItemComponent.CLASSIC]: 'landscape',
    [BannerItemComponent.SPLIT]: 'landscape',
    [BannerItemComponent.BACKGROUND]: 'landscape',
    [BannerItemComponent.BACKGROUND_BLUR]: 'landscape',
    [BannerItemComponent.ORGANIZATION_BANNER]: 'landscape',
    [BannerItemComponent.CAROUSEL]: 'landscape',
}

export function rowItemTypeToAssetType(rowItem = RowItemComponent.ROW_ITEM_LANDSCAPE) {
    return ROW_ITEM_TYPE_TO_ASSET_ID[rowItem]
}

export function itemIsArticle(item: ItemInRow | null | undefined): item is Article {
    return item?.itemType === ROW_ITEM_TYPES.ARTICLE
}

export function itemIsTvChannel(item: ItemInRow | null | undefined): item is TvChannel {
    return item?.itemType === ROW_ITEM_TYPES.TV_CHANNEL
}

export function itemIsTag(item: ItemInRow | null | undefined): item is Tag {
    return item?.itemType === ROW_ITEM_TYPES.TAG
}

export function itemIsVideo(item: ItemInRow | null | undefined): item is Video {
    return item?.itemType === ROW_ITEM_TYPES.VIDEO
}

export function itemIsAdvertisement(item: ItemInRow | Advertisement | null | undefined): item is Advertisement {
    return item?.itemType === ROW_ITEM_TYPES.ADVERTISEMENT
}

export function itemIsApplication(item: ItemInRow | Advertisement | null | undefined): item is Application {
    return item?.itemType === ROW_ITEM_TYPES.APPLICATION
}

export function rowIsBanner(row: Row): row is RowBanner {
    return row.rowComponent === RowComponent.BANNER
}

export function rowIsStandard(row: Row): row is RowStandard {
    return row.rowComponent === RowComponent.ROW
}

export function createZeroItems(count = 6) {
    return Array.from(Array(count).keys()).map(() => null)
}

export function getInnerLabelForItem(item?: ItemInRow | null, rowType?: ScreenRowType): string | undefined {
    if (itemIsVideo(item) && rowType === 'continueToWatch' && item.episodeNumber) {
        // TODO translator in core-react doesn't work, for now leaving SK translation (joj feature)
        return Translator.t('{{episodeNumber}}. epizóda', { episodeNumber: item.episodeNumber })
    }

    return undefined
}
