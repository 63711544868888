import { useCallback, useEffect, useState } from 'react'


interface UseInViewportProps {
    callback: () => void
}

interface UseInViewportReturn<T> {
    isInViewport: boolean
    ref: React.RefCallback<T>
}

export const useInViewport = <T extends HTMLElement>({ callback }: UseInViewportProps): UseInViewportReturn<T> => {
    const [isInViewport, setIsInViewport] = useState(false)
    const [refElement, setRefElement] = useState<T | null>(null)

    const setRef = useCallback((node: T | null) => {
        if (node !== null) {
            setRefElement(node)
        }
    }, [])

    useEffect(() => {
        if (refElement && !isInViewport) {
            const observer = new IntersectionObserver(
                ([entry]) => entry.isIntersecting && setIsInViewport(true),
            )
            observer.observe(refElement)

            return () => {
                observer.disconnect()
            }
        }
    }, [isInViewport, refElement])

    useEffect(() => {
        if (isInViewport) {
            callback()
        }
    })

    return { isInViewport, ref: setRef }
}